<template>
  <b-card class="w-100">
    <b-row>
      <b-col
        sm="12"
        md="3"
        class="mb-1 mb-sm-2 my-md-2 d-flex justify-content-center align-items-center w-100"
      >
        <b class="font-medium-1"> {{ headerTitle }} </b>
      </b-col>
      <b-col
        sm="4"
        md="3"
        class="d-flex justify-content-center align-items-center pt-1"
      >
        <b-form-group label="Select From Date" class="w-100">
          <b-card no-body>
            <flat-pickr
              v-model="fromDate"
              :config="{
                dateFormat: 'Y-m-d',
                minDate: new Date(new Date().getFullYear(), 0, 1), //Limit range to current year only. cannot obtain months data in getAnalytics() due to .slice() method
                maxDate: toDate,
              }"
              class="form-control font-small-3 w-100"
              placeholder="Choose Date"
              readonly="readonly"
              @on-change="onDateChange('FROM')"
            />
          </b-card>
        </b-form-group>
      </b-col>
      <b-col
        sm="4"
        md="3"
        class="d-flex justify-content-center align-items-center pt-1"
      >
        <b-form-group label="Select To Date" class="w-100">
          <b-card no-body>
            <flat-pickr
              v-model="toDate"
              :config="{
                dateFormat: 'Y-m-d',
                minDate: fromDate,
              }"
              class="form-control font-small-3 w-100"
              placeholder="Choose Date"
              readonly="readonly"
              @on-change="onDateChange('TO')"
            />
          </b-card>
        </b-form-group>
      </b-col>
      <b-col
        sm="4"
        md="3"
        class="d-flex justify-content-center align-items-center"
      >
        <b-button
          variant="gradient-primary"
          class="w-100 box-shadow-1 font-small-3 mr-1"
          @click="handleExtract()"
        >
          Extract Analysis
        </b-button>
        <!-- <v-select
          v-model="exportType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="exportList"
          :clearable="false"
          label="type"
          class="font-small-3 w-100"
          @input="onChangeExportType()"
        >
          <template #option="option">
            <span>{{ option.type }}</span>
          </template>
        </v-select> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  name: "AnalyticsHeader",
  components: {
    flatPickr,
    vSelect,
  },

  props: {
    headerTitle: {
      type: String,
      required: true,
    },
  },

  mounted() {},

  methods: {
    onDateChange(type) {
      const date = type === "FROM" ? this.fromDate : this.toDate;
      this.$emit("onDateChange", date, type);
    },

    onChangeExportType() {
      this.$emit("onChangeExportType", this.exportType.type);
    },

    handleExtract() {
      this.$emit("extractAnalysis");
    },
  },

  data() {
    return {
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      exportType: "CSV",
      exportList: [
        {
          type: "CSV",
        },
        // {
        //   type: "PDF",
        // },
        {
          type: "Excel",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
