<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <AnalyticsHeader
          headerTitle="USER DASHBOARD"
          @onDateChange="updateDate"
          @extractAnalysis="extractAnalysis"
        />
        <b-card>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import AnalyticsHeader from "./components/AnalyticsHeader.vue";

export default {
  components: {
    MyTable,
    AnalyticsHeader,
  },

  async created() {
    this.getAnalyticsData();
  },

  methods: {
    updateDate(date, type) {
      if (type === "FROM") {
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
      this.getAnalyticsData();
    },
    getAnalyticsData() {
      this.isLoading = true;
      this.$http
        .get(`analytics/users/${this.fromDate}/${this.toDate}`)
        .then((response) => {
          if (response.data.status) {
            this.rows = response.data.data;
          } else {
            this.rows = [];
          }
          this.isLoading = false;
        });
    },
    async extractAnalysis() {
      const response = await this.$http.get(
        `analytics_report/users/${this.fromDate}/${this.toDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `UsersAnalytics_${this.fromDate}-${this.toDate}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  },

  data() {
    return {
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      columns: [
        {
          label: "Name",
          field: "name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Total Logpoints Spent",
          field: "total_logpoints_spent",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
